import React from 'react';
import history from "./utils/history";
import './App.scss';
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "./utils/axiosFetch";
import {Route, Router, Switch} from "react-router-dom";
import {adminPrefix} from "./Admin/appAdminRoutes";
import appSupervisorRoutes, {supervisorPrefix} from "./Supervisor/appSupervisorRoutes";
import logo from "./assets/img/logo.png";
import TagManager from "react-gtm-module";
import qs from "query-string";
import {Cookies} from "react-cookie";

const AppAdmin = React.lazy(() => import('AppAdmin'));
const AppSupervisor = React.lazy(() => import('AppSupervisor'));
const AppWorkshop = React.lazy(() => import('AppWorkshop'));

if(process.env.NODE_ENV === 'production' && process.env?.REACT_APP_GTM_ID){
  TagManager.initialize({gtmId: process.env.REACT_APP_GTM_ID})
}

const params = qs.parse(window.location.search);
if(params.auth){
  localStorage.jwt = params.auth;
  const cookies = new Cookies();
  cookies.set('BEARER', params.auth, {
    maxAge: 60*60*24*365,
  })
}else if(params.auth_supervisor){
  localStorage.jwtSupervisor = params.auth_supervisor;
  const cookies = new Cookies();
  cookies.set('BEARER', params.auth_supervisor, {
    maxAge: 60*60*24*365,
  })
  history.push(appSupervisorRoutes.main);
}

const loadingBox = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse" /></div>

export const LoadingLayout = () => {
  return <div className="d-flex flex-column align-items-center justify-content-center mt-3">
    <img src={logo} style={{maxWidth: '85%', maxHeight: 80}} />
    <div className="p-3">
      <i className="fas fa-spin fa-spinner fa-2x" />
    </div>
  </div>
}

const App = () => {
  return <Router history={history}>
    <React.Suspense fallback={loadingBox()}>
      <Switch>
        <Route path={adminPrefix} name="Panel administracyjny" render={props => <AppAdmin {...props} />}/>
        <Route path={supervisorPrefix} name="Panel zarządzania" render={props => <AppSupervisor {...props} />}/>
        <Route path={'/'} name="Warsztat" render={props => <AppWorkshop {...props} />}/>
      </Switch>
    </React.Suspense>
  </Router>
};

App.propTypes = {

};

export default App;